<ion-header>
  <ion-toolbar>
    <ion-title>Create Invoice</ion-title>
    <ion-buttons slot="start">
      <ion-button slot="start" fill="clear" color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button slot="end" fill="clear" color="dark" (click)="form.toggleFilled()">
        Bill 100%
        <ion-icon slot="start" [name]="form.filled ? 'checkbox-outline' : 'square-outline'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <gc-invoice-header
      [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
      [company]="workOrderRelations.currentWorkOrderCompany$ | push"
      [date]="todaysDate"
    >
    </gc-invoice-header>
    <gc-create-invoice-form
      [workOrder]="workOrders.current$ | push"
      [items]="workOrderRelations.currentInvoiceableWorkOrderItems$ | push"
      [form]="form"
      (saved)="invoiceAndPayment.reviewInvoice($event)"
    ></gc-create-invoice-form>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <div
          class="alert alert__warning text-center"
          *ngIf="form | pendingInvoiceTotal | greaterThanEqualToAllowance: maximumAllowableInvoiceAmount"
        >
          Invoices must be less than {{ maximumAllowableInvoiceAmount | currency }}.
        </div>
        <ion-text color="dark" class="ion-text-end">
          <h5>TOTAL: {{ form | pendingInvoiceTotal | currency }}</h5>
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ng-container *ngIf="workOrders.current$ | push as workOrder">
          <ion-button
            expand="block"
            color="dark"
            mode="ios"
            [disabled]="
              form.invalid || (form | pendingInvoiceTotal | greaterThanEqualToAllowance: maximumAllowableInvoiceAmount)
            "
            (click)="save(workOrder)"
            >Review Invoice
          </ion-button>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
