import { AbstractControl, UntypedFormArray, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';
import { PendingInvoice } from '../../domains/models/pending-invoice';
import { numberOfUnitsStillRemaining } from '../../shared/pipes/number-of-units-still-pending.pipe';
import { totalNormalizedPrice } from '../../shared/pipes/total-normalized-price.pipe';
import { TypedFormGroup } from '../../shared/utils/typed-form-group';
import { GenericValidators } from '../../shared/validators/generic.validators';
import { InvoiceValidators } from '../../shared/validators/invoice.validators';

export class PendingInvoiceForm extends TypedFormGroup {
  readonly items = this.getFormArray('items') as InvoiceItemFormArray;

  get updatedModel(): Partial<PendingInvoice> {
    return {
      ...this.model,
      ...this.value,
    };
  }

  get filled(): boolean {
    return this.items.itemForms.every(itemForm => itemForm.filled);
  }

  get totalAmountToInvoice(): number {
    return this.items.totalAmountToInvoice;
  }

  constructor(private model: Partial<PendingInvoice>) {
    super({
      controls: {
        items: new InvoiceItemFormArray(model.items?.map(item => new InvoiceItemForm(item)) ?? []),
      },
      validatorOrOpts: [InvoiceValidators.atLeastOneItemInvoiced],
    });
  }

  toggleFilled() {
    this.filled
      ? this.items.itemForms.forEach(itemForm => itemForm.emptyAmountToInvoice())
      : this.items.itemForms.forEach(itemForm => itemForm.fillAmountToInvoice());
  }

  clearSubscriptions() {
    this.items.clearSubscriptions();
  }
}

export class InvoiceItemFormArray extends UntypedFormArray {
  readonly itemForms = this.controls as InvoiceItemForm[];

  get totalAmountToInvoice(): number {
    return this.itemForms.reduce((total, itemForm) => total + itemForm.amountToInvoice.value, 0);
  }

  constructor(controls: AbstractControl[]) {
    super(controls);
  }

  override patchValue(value: InvoiceableWorkOrderItem[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    value.slice(this.controls.length).forEach(val => this.push(new InvoiceItemForm(val)));
  }

  clearSubscriptions() {
    this.itemForms.forEach(item => item.sub.unsubscribe());
  }
}

export class InvoiceItemForm extends TypedFormGroup {
  readonly amountToInvoice = this.getFormControl('amountToInvoice');
  sub: Subscription;

  get updatedModel(): InvoiceableWorkOrderItem {
    return {
      ...this.model,
      ...this.value,
    };
  }

  get hasValue(): boolean {
    return !!this.amountToInvoice.value;
  }

  get additionalInvoicedTotalAmount(): number {
    return +((this.model?.pricePerUnit ?? 0) * this.amountToInvoice.value).toFixed(2);
  }

  get totaledAmount(): number {
    return this.amountToInvoice.value
      ? +(
          (this.model?.pricePerUnit ?? 0) * this.amountToInvoice.value +
          (this.model?.applicableChangeOrders?.length
            ? totalNormalizedPrice(this.model.applicableChangeOrders, this.model.pricePerUnit)
            : 0)
        ).toFixed(2)
      : 0;
  }

  get filled(): boolean {
    return this.amountToInvoice.value === numberOfUnitsStillRemaining(this.model);
  }

  constructor(private model?: InvoiceableWorkOrderItem) {
    super({
      controls: {
        amountToInvoice: [
          model?.amountToInvoice,
          [
            //Validators.max((model?.numberOfUnits ?? 0) - (model?.numberOfUnitsAlreadyInvoiced ?? 0)),
            Validators.max(numberOfUnitsStillRemaining(model)),
            // InvoiceValidators.amountSurpassesChangeOrderTotal(model),
            InvoiceValidators.amountAtMostInvoiceableTotal(model),
            GenericValidators.incrementAmount(model?.unitOfMeasureSymbol),
          ],
        ],
      },
    });
    this.sub = this.amountToInvoice.valueChanges.subscribe(value =>
      this.amountToInvoice.setValue(value, {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: !isNaN(value),
      }),
    );
  }

  toggleFilled() {
    this.filled ? this.emptyAmountToInvoice() : this.fillAmountToInvoice();
  }

  emptyAmountToInvoice() {
    this.patchValue({ amountToInvoice: null });
  }

  fillAmountToInvoice() {
    this.patchValue({ amountToInvoice: numberOfUnitsStillRemaining(this.model) });
  }
}
